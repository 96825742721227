import React from 'react';
import PropTypes from 'prop-types';
import "./OldschoolStatsRenderer.css"
import D6Renderer from '../D6Renderer/D6Renderer';

const OldschoolStatsRenderer = (props) => {
   const abilityNames = ["Str", "Int", "Wis", "Dex", "Con", "Cha"];

   return (<table className="OldschoolStatsRenderer">
      {props.results.map((currentResult, index) => (
         <tr className="IndividualResults">
            <td className="OldSchoolStatsRendererLCell">{abilityNames[index]}&nbsp;</td>
            <td className="OldSchoolStatsRendererLCell">
               &nbsp;
               {currentResult.individualDice.map((currentDiceResult) => (
                  <><D6Renderer value={currentDiceResult} />&nbsp;</>
               ))}
            </td>
            <td className="OldSchoolStatsRendererLCell">&nbsp;=&nbsp;</td>
            <td className="OldSchoolStatsRendererRCell">{currentResult.total}</td>
         </tr>
      ))}
   </table>);
};

OldschoolStatsRenderer.propTypes = {
   results: PropTypes.array
};

OldschoolStatsRenderer.defaultProps = {};

export default OldschoolStatsRenderer;
