import React from 'react';
import PropTypes from 'prop-types';
import { FloatingActionButtonWrapper } from './FloatingActionButton.styled';
import "./FloatingActionButton.css"

const FloatingActionButton = (props) => (
 <FloatingActionButtonWrapper>
    <div className="FloatingActionButton" onClick={props.onclick}>{props.children}</div>
 </FloatingActionButtonWrapper>
);

FloatingActionButton.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
   onclick: PropTypes.func
};

FloatingActionButton.defaultProps = {};

export default FloatingActionButton;
