import React from 'react';
import { useNotificationService } from '../../services/NotificationService';
import "./Notifications.css";
import Notification from './Notification';

const NotificationContainer = () => {

   const { notifications } = useNotificationService();

   return (
      <div className="NotificationContainer">
         {notifications.map((it) => <Notification notification={it} />)}
      </div>
   )
};

export default NotificationContainer;
