import React from 'react';
import PropTypes from 'prop-types';
import "./DiceResults.css";
import ModernStatsRenderer from '../ModernStatsRenderer/ModernStatsRenderer';
import OldschoolStatsRenderer from '../OldschoolStatsRenderer/OldschoolStatsRenderer';
import DiceExpressionResultRenderer from '../DiceExpressionResultRenderer/DiceExpressionResultRenderer';

const DiceResults = (props) => {

   const algorithm = props.linkDetails.algorithm;

   return (
      <div className="DiceResultsWrapper">
         <p>
            Here are the results of the roll that <span className="RequesterName">{props.linkDetails.createdBy} </span>
            requested from <span className="RecipientName">{props.linkDetails.createdFor}</span>!
         </p>
         {algorithm === "stats_4d6dl" && <ModernStatsRenderer results={props.linkDetails.results} />}
         {algorithm === "stats_3d6dtl" && <OldschoolStatsRenderer results={props.linkDetails.results} />}
         {algorithm === "expression" && <DiceExpressionResultRenderer results={props.linkDetails.results} />}
      </div>
   )
};

DiceResults.propTypes = {
   linkDetails: PropTypes.object
};

DiceResults.defaultProps = {};

export default DiceResults;
