import React, { Fragment, useState } from 'react';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import Dropdown from '../Dropdown/Dropdown';
import Option from "../Dropdown/Option/Option";
import { createLink } from "../../services/DiceWitnessService";
import { useNotificationService } from '../../services/NotificationService';
import { useCreateLinkFormModel } from '../../models/CreateLinkFormModel';
import ModalDialog from '../ModalDialog/ModalDialog';
import "./CreateLinkForm.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const CreateLinkForm = () => {

   // initialize state
   const [linkInfo, setLinkInfo] = useState({
      showDialog: false,
      linkUrl: null,
      linkCopied: false
   });

   const { model, validate, getValidationStatus, applyFieldValue } = useCreateLinkFormModel();

   const { addNotification } = useNotificationService();

   const handleChange = (e) => {
      applyFieldValue(e.target.name, e.target.value);
   };

   const handleSelectValue = (e) => {
      applyFieldValue(e.target.name, e.target.value);
      validate(e.target.name);
   }

   const handleSubmit = async (e) => {
      e.preventDefault();
      createLink({
         createdBy: model.formFields.yourName.value,
         createdFor: model.formFields.theirName.value,
         label: model.formFields.label.value,
         algorithm: model.formFields.algorithm.value,
         algorithmOpts: {
            expression: model.formFields.expression.value
         }
      }).then((data) => {
         setLinkInfo({
            linkUrl: `${window.location.protocol}//${window.location.host}/links/${data.linkId}`,
            showDialog: true
         });
         addNotification({ message: "Link created." });
      }).catch((err) => {
         console.error(err);
         addNotification({ message: "Link creation failed.", severity: "ERROR" });
      });
   };

   return (
      <Fragment>
         <div className="CreateLinkForm">
            <TextField name="yourName" placeholder="Your name" onchange={handleChange} onblur={() => validate("yourName")} validationStatus={getValidationStatus("yourName")} />
            <TextField name="theirName" placeholder="Their name" onchange={handleChange} onblur={() => validate("theirName")} validationStatus={getValidationStatus("theirName")} />
            <TextField name="label" placeholder="Send them a message!" onchange={handleChange} onblur={() => validate("label")} validationStatus={getValidationStatus("label")} />
            <Dropdown name="algorithm" placeholder="What should they roll?" onchange={handleSelectValue} validationStatus={getValidationStatus("algorithm")}>
               <Option label="Modern Stats (4d6 drop lowest)" value="stats_4d6dl" />
               <Option label="Oldschool Stats (3d6 in order)" value="stats_3d6dtl" />
               <Option label="User defined dice" value="expression" />
            </Dropdown>
            {model.formFields.algorithm.value === 'expression' &&
               <TextField name="expression" placeholder="Dice expression" onchange={handleChange} onblur={() => validate("expression")} validationStatus={getValidationStatus("expression")} />}
            {!model.canSubmit &&
               <ul className="ValidationMessages">
                  {Object.keys(model.validationResults).map((fieldName) => (
                     <>
                        {model.validationResults[fieldName].messages &&
                           <>
                              {model.validationResults[fieldName].messages.map((msg) => (
                                 <li key={fieldName}><span className="ErrorIcon"><FontAwesomeIcon icon={faExclamationCircle} /></span> {msg}</li>
                              ))}
                           </>
                        }
                     </>
                  ))}
               </ul>
            }
            <Button label="Create Link" onclick={handleSubmit} disabled={!model.canSubmit} />
         </div>
         {linkInfo.showDialog &&
            <ModalDialog onclose={() => setLinkInfo({ ...linkInfo, showDialog: false, linkCopied: false })}>
               <p>Grab the link from the textbox below to share it with the recipient.</p>
               <TextField value={linkInfo.linkUrl} readonly={true} showCopyIcon={true} copyIconCallback={() => setLinkInfo({ ...linkInfo, linkCopied: true })} />
               {linkInfo.linkCopied && <p className="CopiedToClipboardNotice">Link has been copied to clipboard.</p>}
            </ModalDialog>}
      </Fragment>
   );
}

export default CreateLinkForm;
