import React from 'react';
import PropTypes from 'prop-types';
import "./Option.css";

const Option = (props) => (
    <option className="Option" value={props.value}>{props.label}</option>
);

Option.propTypes = {
   label: PropTypes.string,
   value: PropTypes.string
};

Option.defaultProps = {};

export default Option;
