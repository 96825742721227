import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceOne, faDiceTwo, faDiceThree, faDiceFour, faDiceFive, faDiceSix } from "@fortawesome/free-solid-svg-icons";
import "./D6Renderer.css";

const D6Renderer = (props) => {

   const icons = [
      faDiceOne,
      faDiceTwo,
      faDiceThree,
      faDiceFour,
      faDiceFive,
      faDiceSix
   ]

   return (
      <div className={`D6Renderer ${props.highlight ? "D6RendererHighlight" : ""}`}>
         <FontAwesomeIcon icon={icons[props.value - 1]} />
      </div>
   );
};

D6Renderer.propTypes = {
   value: PropTypes.number,
   highlight: PropTypes.bool
};

D6Renderer.defaultProps = {};

export default D6Renderer;
