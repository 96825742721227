import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DiceResults from '../DiceResults/DiceResults';
import FreshLinkDetails from '../FreshLinkDetails/FreshLinkDetails';
import { getLinkDetails } from '../../services/DiceWitnessService';
import { useNotificationService } from '../../services/NotificationService';


const LinkDetails = () => {

   const { linkId } = useParams();
   const [linkDetails, setLinkDetails] = useState({
      fetched: false,
      createdBy: null,
      createdFor: null,
      label: null,
      algorithm: null,
      results: null
   });
   const [error, setError] = useState(null);
   
   const applyResults = (results) => {
      setLinkDetails({
         ...linkDetails,
         results: results
      });
   };

   const { addNotification } = useNotificationService();

   useEffect(() => {
      const fetchData = async () => {
         try {
            const data = await getLinkDetails({linkId: linkId});
            setLinkDetails({
               fetched: true,
               createdBy: data.createdBy,
               createdFor: data.createdFor,
               label: data.label,
               algorithm: data.algorithm,
               results: data.results
            });
         } catch (err) {
            console.error(err);
            if ("Not Found" === err.name) {
               setError("We're sorry, but we couldn't find the link you are looking for :-(");
            } else {
               setError(`Something has gone terribly wrong: ${err.message}`);
            }
            addNotification({ message: err.message, severity: "ERROR" });
         }
      };
      fetchData();
   // eslint-disable-next-line
   }, []);

   return (
      <div>
         {error ? (
            <div>{error}</div>
         ) : (
            <>
               {linkDetails.fetched ? (
                  <>
                     {linkDetails.results ? (
                        <DiceResults linkDetails={linkDetails} />
                     ) : (
                        <FreshLinkDetails
                           linkId={linkId}
                           linkDetails={linkDetails}
                           diceResultsCallback={applyResults} />
                     )}
                  </>
               ) : (
                  <div>Fetching link details. Please stand by.</div>
               )}
            </>
         )}
      </div>
   );
};

export default LinkDetails;
