const baseUrl = "https://vbirbh7qk3.execute-api.eu-central-1.amazonaws.com/prod";

export const createLink = (opts) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/create-link`,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                createdBy: opts.createdBy,
                createdFor: opts.createdFor,
                label: opts.label,
                algorithm: opts.algorithm,
                algorithmOpts: opts.algorithmOpts
            })
        }).then((response) => { 
            if (response.ok) {
                response.json().then((data) => resolve(data));
            } else {
                reject(new Error("Unexpected Error", "Unexpected error while creating a link."));
            }
        }).catch((err) => reject(err));
    });
};

export const getLinkDetails = (opts) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/get-link-details`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                linkId: opts.linkId
            })
        }).then((response) => {
            if (response.ok) {
                response.json().then((data) => resolve(data));
            } else {
                response.json().then((data) => reject({ statusCode: response.status, message: data }));
            }
        }).catch((err) => reject(err));
    });
};

export const rollDice = (opts) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/roll-dice`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "linkId": opts.linkId
            })
        }).then((response) => {
            if (response.ok) {
                response.json().then((data) => resolve(data));
            } else {
                reject(new Error("Unexpected Error", "Unexpected error while rolling the dice."));
            }
        }).catch((err) => reject(err));
    });
};