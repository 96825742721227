import React from 'react';
import PropTypes from 'prop-types';
import "./DiceExpressionResultRenderer.css";

const DiceExpressionResultRenderer = (props) => (
   <div className="DiceExpressionResultRenderer">
      <p className="GrandTotal">{props.results.grandTotal}</p>
      <p className="Expression">({props.results.expression})</p>
   </div>
);

DiceExpressionResultRenderer.propTypes = {
   results: PropTypes.object
};

DiceExpressionResultRenderer.defaultProps = {};

export default DiceExpressionResultRenderer;
