import React, { Fragment } from 'react';
import { HomePageWrapper } from './HomePage.styled';
import CreateLinkForm from "../CreateLinkForm/CreateLinkForm";
import "./HomePage.css";

const HomePage = () => (
<Fragment>
   <HomePageWrapper className="HomePage">
      <p>Ask someone to roll some dice!</p>
      <CreateLinkForm />
   </HomePageWrapper>
 </Fragment>
);

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
