import { atom, useRecoilState } from 'recoil';

export const createLinkFormState = atom({
    key: "createLinkFormState",
    default: {
        canSubmit: false,
        formFields: {
            yourName: {
                label: "Your name",
                value: "",
                constraints: {
                    mandatory: true,
                    maxLength: 100
                }
            },
            theirName: {
                label: "Their name",
                value: "",
                constraints: {
                    mandatory: true,
                    maxLength: 100
                }
            },
            label: {
                label: "Send them a message!",
                value: "",
                constraints: {
                    mandatory: true,
                    maxLength: 250
                }
            },
            algorithm: {
                label: "What should they roll?",
                value: "",
                constraints: {
                    mandatory: true
                }
            },
            expression: {
                label: "Dice expression",
                value: "",
                transformations: {
                    removeWhitespace: true
                },
                dependencies: [{
                    fieldName: "algorithm",
                    fieldValue: "expression"
                }],
                removeWhitespace: true,
                constraints: {
                    mandatory: true,
                    matchPattern: /^([+\-]?\d*(?:[dD]\d*)?)+$/
                }
            }
        },
        validationResults: {
            yourName: {},
            theirName: {},
            label: {},
            algorithm: {},
            expression: {}
        }
    },
    dangerouslyAllowMutability: true
});


export const useCreateLinkFormModel = () => {

    const [model, setModel] = useRecoilState(createLinkFormState);

    const setValidationMessages = (fieldName, messages) => {
        setModel((oldModel) => {
            const newModel = { ...oldModel };
            newModel.validationResults[fieldName] = {
                validationStatus: messages.length > 0 ? "invalid" : "valid",
                messages: messages
            };
            return newModel;
        })
    };

    const checkCanSubmit = () => {
        setModel((oldModel) => {
            const newModel = { ...oldModel };
            const applicableFields = getApplicableFields();
            newModel.canSubmit = Object.keys(newModel.validationResults)
                .filter((fieldName) => applicableFields.includes(fieldName))
                .map((fieldName) => model.validationResults[fieldName].validationStatus)
                .filter((it) => it !== "valid").length === 0;
            return newModel;
        });
    };

    const validate = (fieldName) => {
        const constraints = model.formFields[fieldName].constraints;
        const fieldValue = model.formFields[fieldName].value;
        const fieldLabel = model.formFields[fieldName].label;

        const validationMessages = [];

        if (constraints.mandatory && !fieldValue) {
            validationMessages.push(`Please provide a value for the mandatory field labeled '${fieldLabel}'`);
        }
        if (constraints.mandatoryIf) {
            const dependencyName = constraints.mandatoryIf.dependencyName;
            const expectedDependencyValue = constraints.mandatoryIf.dependencyValue;
            const actualDependencyValue = model.formFields[dependencyName].value;
            if (expectedDependencyValue === actualDependencyValue && !fieldValue) {
                validationMessages.push(`Please provide a value for the mandatory field labeled '${fieldLabel}'`);
            }
        }
        if (constraints.matchPattern && !constraints.matchPattern.test(fieldValue)) {
            validationMessages.push(`Incorrect format provided for the field labeled '${fieldLabel}'`);
        }
        setValidationMessages(fieldName, validationMessages);
        checkCanSubmit();
    }

    const getApplicableFields = () => {
        return Object.keys(model.formFields).filter((fieldName) => {
            const formField = model.formFields[fieldName];
            var isApplicable = true;
            if (formField.dependencies) {
                formField.dependencies.forEach((dep) => {
                    if (dep.fieldValue) {
                        isApplicable = isApplicable && dep.fieldValue === model.formFields[dep.fieldName].value;
                    } else {
                        isApplicable = isApplicable && model.formFields[dep.fieldName].value;
                    }
                });
            }
            return isApplicable;
        });
    }

    const getValidationStatus = (fieldName) => {
        const validationResult = model.validationResults[fieldName];
        if (validationResult) {
            return validationResult.validationStatus;
        } else {
            return undefined;
        }
    }

    const applyFieldValue = (fieldName, fieldValue) => {
        setModel((oldModel) => {
            const sanitizedValue = model.formFields[fieldName].removeWhitespace
                ? fieldValue.replace(/ /g, '')
                : fieldValue
            const newModel = { ...oldModel };
            newModel.formFields[fieldName].value = sanitizedValue;
            return newModel;
        });
    }

    return { model, validate, getValidationStatus, applyFieldValue }
}

