import React from 'react';
import PropTypes from 'prop-types';
import "./Dropdown.css";

const Dropdown = (props) => (
<select name={props.name} className="Dropdown" onChange={props.onchange} onblur={props.onblur}>
   { props.placeholder && (
      <option value="" hidden>{props.placeholder}</option>
   )}
   {props.children}
</select>
);

Dropdown.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onchange: PropTypes.func,
  onblur: PropTypes.func
};

Dropdown.defaultProps = {};

export default Dropdown;
