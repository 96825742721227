import React from 'react';
import PropTypes from 'prop-types';
import './ModalDialog.css';
import Button from '../Button/Button';

const ModalDialog = (props) => {
   const linkUrl = `${window.location.host}/links/${props.linkId}`
   console.log(`linkUrl = ${linkUrl}`)
   return (
   <div className="ModalPane">
      <div className="ModalDialog">
         {props.children}
         <Button label={props.closeButtonLabel} onclick={props.onclose} />
      </div>
   </div>)
}

ModalDialog.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
   onclose: PropTypes.func,
   closeButtonLabel: PropTypes.string
};

ModalDialog.defaultProps = {
   closeButtonLabel: "Close"
};

export default ModalDialog;
