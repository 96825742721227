import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import "./ModernStatsRenderer.css";
import D6Renderer from '../D6Renderer/D6Renderer';

const ModernStatsRenderer = (props) => {
   return (
      <div className="ModernStatsWrapper">
         {props.results.map((currentResult, i) => {
            const key = `result-${i}`;
            return (<div className="IndividualResults" key={key}>
               {currentResult.individualDice.toReversed().map((diceValue, j) => {
                  const diceKey = `${key}-${j}`;
                  const isMinimumDice = (j === 3);
                  return (<Fragment><D6Renderer value={diceValue} key={diceKey} highlight={isMinimumDice} />&nbsp;</Fragment>)
               })} = {currentResult.total}
            </div>);
         })}
      </div>
   )
};

ModernStatsRenderer.propTypes = {
   results: PropTypes.array
};

ModernStatsRenderer.defaultProps = {};

export default ModernStatsRenderer;
