import { atom, useRecoilState } from 'recoil';

const DEFAULT_TTL = 10000;

const notificationsState = atom({
  key: 'notifications',
  default: [],
});

export const useNotificationService = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const addNotification = (notification) => {
    const notificationId = Date.now();
    notification.id = notificationId;
    if (!notification.ttl) {
      notification.ttl = DEFAULT_TTL;
    }

    setNotifications((previousNotifications) => [
      ...previousNotifications,
      notification,
    ]);

    setTimeout(() => {
      setNotifications((previousNotifications) =>
        previousNotifications.filter((it) => it.id !== notificationId)
      );
    }, notification.ttl);
  };

  const removeNotification = (notification) => {
    const notificationId = notification.id;
    setNotifications((previousNotifications) =>
      previousNotifications.filter((it) => it.id !== notificationId)
    );
  };

  return { notifications, addNotification, removeNotification };
};
