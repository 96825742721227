import React from 'react';
import PropTypes from 'prop-types';
import { useNotificationService } from '../../services/NotificationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "./Notifications.css";

const getSeverityStyleClass = (severity) => {
   switch (severity) {
      case "ERROR": return "NotificationError";
      case "WARNING": return "NotificationWarning";
      default: return "NotificationInfo";
   }
}

const Notification = (props) => {
   const { removeNotification } = useNotificationService();
   const severityStyleClass = getSeverityStyleClass(props.notification.severity);

   return (
      <div className={`Notification ${severityStyleClass}`}>
         {props.notification.message} <span className="CloseIcon"><FontAwesomeIcon icon={faCircleXmark} onClick={() => removeNotification(props.notification)} /></span>
      </div>
   )
};

Notification.propTypes = {
   notification: PropTypes.object
};

Notification.defaultProps = {};

export default Notification;
