import React from 'react';
import PropTypes from 'prop-types';
import './TextField.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faFaceSadTear, faCheck } from "@fortawesome/free-solid-svg-icons";

const TextField = (props) => {

   const copyToClipboard = async () => {
      if (props.copyIconCallback) {
         props.copyIconCallback();
      }
      await navigator.clipboard.writeText(props.value)
   }

   return (<div className={`TextFieldWrapper ${props.validationStatus === "invalid" ? 'TextFieldInvalid' : ''}`}>
      <input
         className="TextField"
         type="text"
         name={props.name}
         placeholder={props.placeholder}
         onChange={props.onchange}
         onBlur={props.onblur}
         value={props.value}
         readOnly={props.readonly}
         maxLength={props.maxLength} />
      {props.validationStatus === "valid" && <FontAwesomeIcon className="Icon" icon={faCheck} />}
      {props.validationStatus === "invalid" && <FontAwesomeIcon className="Icon" icon={faFaceSadTear} />}
      {props.showCopyIcon && <FontAwesomeIcon className="Icon" icon={faCopy} onClick={copyToClipboard} />}
   </div>
   )
};

TextField.propTypes = {
   "name": PropTypes.string,
   "placeholder": PropTypes.string,
   "onchange": PropTypes.func,
   "onblur": PropTypes.func,
   "value": PropTypes.string,
   "readonly": PropTypes.bool,
   "showCopyIcon": PropTypes.bool,
   "copyIconCallback": PropTypes.func,
   "maxLength": PropTypes.number,
   "validationStatus": PropTypes.string
};

TextField.defaultProps = {
   "value": null,
   "validationStatus": undefined
};

export default TextField;
