import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import "./FreshLinkDetails.css";
import Button from "../Button/Button";
import { rollDice } from '../../services/DiceWitnessService';
import { useNotificationService } from '../../services/NotificationService';
import ModalDialog from '../ModalDialog/ModalDialog';

const FreshLinkDetails = (props) => {

   const [results, setResults] = useState([]);
   const [aleaIactaEst, setAleaIactaEst] = useState(false);

   const {addNotification} = useNotificationService();

   const doRollDice = async () => {
      rollDice({ linkId: props.linkId }).then((data) => {
         setAleaIactaEst(true);
         setResults(data.results);
      }).catch((err) => {
         console.error(err);
         addNotification({ message: err.message, severity: "ERROR" });
      });
   };

   return (
      <Fragment>
         <div className="FreshLinkDetails">
            <p>Hey, <span className="RecipientName">{props.linkDetails.createdFor}</span>, we're glad you're here! <span className="RequesterName">{props.linkDetails.createdBy}</span> is asking you to roll some dice! Here is what they say:</p>
            <p className="RequestLabel">"{props.linkDetails.label}"</p>
            <Button label="Take a chance!" onclick={doRollDice} />
         </div>
         {aleaIactaEst && <ModalDialog closeButtonLabel="Show me!" onclose={() => props.diceResultsCallback(results)}>
            <p>Results are in!</p>
         </ModalDialog>}
      </Fragment>
   );
};

FreshLinkDetails.propTypes = {
   linkId: PropTypes.string,
   linkDetails: PropTypes.object,
   diceResultsCallback: PropTypes.func
};

FreshLinkDetails.defaultProps = {};

export default FreshLinkDetails;
