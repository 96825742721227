import React from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from './components/HomePage/HomePage';
import LinkDetails from './components/LinkDetails/LinkDetails';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import FloatingActionButton from './components/FloatingActionButton/FloatingActionButton';
import { RecoilRoot } from 'recoil';
import NotificationContainer from './components/Notifications/NotificationContainer';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/links/:linkId",
    element: <LinkDetails />
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <RecoilRoot>
    <React.StrictMode>
      <RouterProvider router={router} />
      <NotificationContainer />
    </React.StrictMode>
  </RecoilRoot>
);
