import React from 'react';
import PropTypes from 'prop-types';
import "./Button.css";

const Button = (props) => {
   return (
      <div className="ButtonWrapper">
         <button type="button" className={`Button ${props.disabled ? 'ButtonDisabled' : ''}`} onClick={props.onclick} disabled={props.disabled}>{props.label}</button>
      </div>
   )
};

Button.propTypes = {
   label: PropTypes.string,
   onclick: PropTypes.func,
   disabled: PropTypes.bool,
};

export default Button;
